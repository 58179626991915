import { Box, TextArea, TextInput } from "grommet";
import React from "react";
import StandardText from "../standard_text";

const CustomInput = ({ onChange, value, border = 'none', placeholder, ...rest }) => {

    return (
        <TextInput
            onChange={onChange}
            value={value || ''}
            style={{ border }}
            placeholder={placeholder}
            {...rest}
        />
    )
}

const PlaceHolderInput = ({ placeholder, onChange, value, border = 'none', onFocus, ...rest }) => {

    return (
        <Box width={'small'}
            direction='row'
            align="center"
            round='small'
            gap="xsmall"
        >
            <StandardText label={placeholder}/>
            <Box width={'full'}>
                <TextInput
                    plain
                    onChange={onChange}
                    value={value || ''}
                    style={{ border }}
                    onFocus={onFocus}
                    {...rest}
                />
            </Box>
        </Box>
    )
}

const CustomTextArea = ({ onChange, value, border = 'none', ...rest }) => {

    return (
        <TextArea
            onChange={onChange}
            value={value}
            style={{ border }}
            {...rest}
        />
    )
}

export default CustomInput;

export {
    CustomTextArea,
    PlaceHolderInput
}