import { navigate } from "gatsby";
import {
  Box,
  Button,
  Card,
  CheckBox,
  InfiniteScroll,
  Layer,
  TextInput,
} from "grommet";
import { Pause, Play, Search, StatusGoodSmall, Trash } from "grommet-icons";
import React from "react";
import { NewPartner } from "../../components/common/new_item";
import { TypeToConfirmation } from "../../components/elements/confirmation_popup";
import { EventEmitter } from "../../components/elements/event_emitter";
import PrimaryButton from "../../components/elements/primary_button";
import StandardText, { COLOR } from "../../components/elements/standard_text";
import LoadingAnimation from "../../components/loading_animation";
import NavigationFrame from "../../components/nav_frame";
import { OPEN, PAUSED } from "../../service/model_utils";
import service, {
  createPartner,
  deletePartner,
  getPartners,
} from "../../service/partner_service";
import { hasAdminRole } from "../../service/storage_service";
import { getSimpleDateTime } from "../../service/utils";

class Partners extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: undefined,
      itemToDelete: undefined,
      newItem: undefined,
      timerId: undefined,
      query: undefined,
      pagination: { page: 0, more: true },
      selectedPartners: [],
      loading: false,
      partnersToDelete: undefined,
    };
  }

  componentDidMount = () => {
    if (!hasAdminRole()) navigate("/404");
    getPartners('', 1, 100).then((res) => {
      const partners = res.data.data;
      const pagination = { page: 1, more: res.data.data.length === 100 };
      this.setState({ data: partners, pagination });
    });
  };

  deleteItem = (id) => {
    deletePartner(id).then((_) => {
      const data = this.state.data.filter((p) => p.id !== id);
      this.setState({ data, itemToDelete: undefined });
      EventEmitter.dispatch("showMessage", {
        message: "Partner is deleted successfully",
      });
    });
  };

  createItem = async (item) => {
    const res = await getPartners(item.salesforce_account_id);
    if (res.data.data.length) {
      EventEmitter.dispatch("showMessage", {
        message:
          item.salesforce_account_id +
          " exists in the partner: " +
          res.data.data[0].name,
        messageType: "error",
      });
      return;
    }
    createPartner(item).then((res) => {
      this.state.data.push(res.data.data);
      const data = this.state.data.map((p) => p);
      this.setState({ data, newItem: undefined });
      EventEmitter.dispatch("showMessage", {
        message: "Partner is created successfully",
      });
      navigate(`/partners/${res.data.data.id}`);
    });
  };

  onSearch = (query) => {
    this.setState({ query });
    let timerId = this.state.timerId;
    if (timerId) clearTimeout(timerId);
    timerId = setTimeout(() => {
      getPartners(query, 1, 100).then((res) => {
        const data = res.data.data;
        this.setState({ data });
      });
    }, 300);
  };

  onMore = () => {
    let pagination = this.state.pagination;
    if (!pagination.more) return;
    const nextPage = this.state.pagination.page + 1;
    this.setState({ loading: "Loading partners ..." });
    getPartners("", nextPage, 100)
      .then((res) => {
        const data = [...this.state.data, ...res.data.data];
        pagination = {
          ...pagination,
          page: nextPage,
          more: res.data.data.length === 100,
        };
        this.setState({ data, loading: false, pagination });
      })
      .catch((err) => {
        EventEmitter.errorHandler(err);
        this.setState({ loading: false });
      });
  };

  onPartnerSelectedChange = (partner) => {
    partner.selected = partner.selected ? false : true;
    const partners = this.state.selectedPartners;
    if (partners.map((p) => p.id).includes(partner.id)) {
      const index = partners.indexOf(partner);
      partners.splice(index, 1);
    } else {
      partners.push(partner);
    }
    this.setState({
      data: [...this.state.data],
      selectedPartners: [...partners],
    });
  };

  changeStatusInBatch = async () => {
    let i = 1;
    const total = this.state.selectedPartners.length;
    for (const partner of this.state.selectedPartners) {
      const nextStatus = partner.status === PAUSED ? OPEN : PAUSED;
      this.setState({
        loading:
          "Switching status of: " + partner.name + ` (${i++} / ${total})`,
      });
      await service
        .updateStatus(partner.id, nextStatus)
        .then((_) => {
          partner.status = nextStatus;
          partner.selected = false;
        })
        .catch((err) => {
          EventEmitter.errorHandler(err);
        });
    }
    this.setState({
      loading: false,
      selectedPartners: [],
      data: [...this.state.data],
    });
    EventEmitter.dispatch("showMessage", {
      message: "Partners status has been changed successfully!",
    });
  };

  deleteInBatch = async () => {
    let i = 1;
    const partners = this.state.selectedPartners;
    for (const partner of partners) {
      this.setState({
        loading: "Deleting: " + partner.name + ` (${i++} / ${partners.length})`,
      });
      deletePartner(partner.id)
        .then((_) => {})
        .catch(EventEmitter.errorHandler);
    }
    this.setState({
      loading: false,
      selectedPartners: [],
      data: this.state.data.filter(
        (p1) => !partners.map((p) => p.id).includes(p1.id)
      ),
      partnersToDelete: undefined,
    });
    EventEmitter.dispatch("showMessage", {
      message: "Partners have been deleted successfully!",
    });
  };

  render() {
    return (
      <NavigationFrame>
        {this.state.data ? (
          <Box
            width={"xlarge"}
            pad="medium"
            style={{ height: "100vh", minHeight: "auto", overflowY: "auto" }}
            gap="medium"
          >
            <Box
              justify="between"
              width={"full"}
              pad={{ vertical: "small" }}
              direction="row"
              align="center"
              style={{ minHeight: "auto" }}
            >
              <Box
                round={{ size: "small" }}
                border
                justify="center"
                align="center"
                direction="row"
              >
                <TextInput
                  placeholder="Search"
                  size="small"
                  onChange={(event) => this.onSearch(event.target.value)}
                  style={{ border: "none", boxShadow: "none" }}
                />
                <Search />
              </Box>
              <PrimaryButton
                label={"New partner"}
                onClick={() => this.setState({ newItem: {} })}
              />
            </Box>
            <Box width={"full"} gap="small" style={{ minHeight: "auto" }}>
              {this.state.selectedPartners.length > 0 && (
                <PossibleActions
                  partners={this.state.selectedPartners}
                  pauseAll={this.changeStatusInBatch}
                  openAll={this.changeStatusInBatch}
                  deleteAll={() => this.setState({ partnersToDelete: true })}
                />
              )}
              <InfiniteScroll items={this.state.data} onMore={this.onMore}>
                {(partner) => (
                  <LineView
                    partner={partner}
                    key={partner.id}
                    deleteItem={() => this.setState({ itemToDelete: partner })}
                    onSelectedChange={this.onPartnerSelectedChange}
                  />
                )}
              </InfiniteScroll>
            </Box>
          </Box>
        ) : (
          <LoadingAnimation />
        )}

        {this.state.itemToDelete ? (
          <TypeToConfirmation
            description={
              "This operation cannot be reverted. Do you want to proceed?"
            }
            close={() => this.setState({ itemToDelete: undefined })}
            pharse={this.state.itemToDelete.name}
            confirmDelete={() => this.deleteItem(this.state.itemToDelete.id)}
          />
        ) : null}

        {this.state.partnersToDelete ? (
          <TypeToConfirmation
            description={
              "This operation cannot be reverted. Do you want to proceed?"
            }
            close={() => this.setState({ partnersToDelete: undefined })}
            pharse={"Partners"}
            confirmDelete={this.deleteInBatch}
          />
        ) : null}

        {this.state.newItem ? (
          <NewPartner
            onCreate={this.createItem}
            onClose={() => this.setState({ newItem: undefined })}
          />
        ) : null}

        {this.state.loading ? (
          <Layer>
            <Box justify="center" align="center" pad={"large"}>
              <LoadingAnimation text={this.state.loading} />
            </Box>
          </Layer>
        ) : null}
      </NavigationFrame>
    );
  }
}

const LineView = ({ partner, deleteItem, onSelectedChange }) => (
  <Card
    round="xsmall"
    width={"full"}
    background={{ color: "#FFF" }}
    pad={"xsmall"}
    justify="between"
    direction="row"
    style={{ minHeight: "auto" }}
    margin={{ bottom: "small" }}
    key={partner.id}
  >
    <Box align="center" direction="row" pad={{ left: "small" }} gap="small">
      <CheckBox
        checked={partner.selected}
        onChange={(_) => onSelectedChange(partner)}
      />
      <Box align="center" direction="row" pad={{ left: "small" }}>
        <StandardText
          label={`${partner.name} `}
          style={{ fontWeight: "bold" }}
        />
        &nbsp;
        <StandardText label={` - `} />
        &nbsp;
        <StandardText
          label={` ${partner.contact}`}
          color="#999"
          style={{ fontWeight: "bold" }}
        />
        &nbsp;
        <StandardText
          label={` ${partner.hubrise_id}`}
          color="#999"
          style={{ fontWeight: "bold" }}
        />
      </Box>
    </Box>

    <Box align="center" direction="row" pad={{ left: "small" }} gap="medium">
      <Box>
        <StandardText label={getSimpleDateTime(partner.app_connected_at)} color={COLOR.black} />
      </Box>
      <Box>
        <StatusGoodSmall
          color={partner.status === PAUSED ? COLOR.error : COLOR.green}
        />
      </Box>
      <Box
        onClick={(e) => {
          e.stopPropagation();
          deleteItem();
        }}
      >
        <Trash />
      </Box>
      <PrimaryButton
        label={"Open"}
        onClick={() => navigate(`/partners/${partner.id}`)}
      />
    </Box>
  </Card>
);

const PossibleActions = ({ partners, openAll, pauseAll, deleteAll }) => (
  <Box direction="row" gap="small" align="center">
    <StandardText label={`${partners.length} partners selected`} />
    <Button
      size="small"
      icon={<Trash size="small" />}
      label="Delete"
      onClick={deleteAll}
    />
    {partners.every((p) => p.status === PAUSED) && (
      <Button
        size="small"
        icon={<Play size="small" color={COLOR.green} />}
        label={"Open"}
        onClick={openAll}
      />
    )}
    {partners.every((p) => p.status !== PAUSED) && (
      <Button
        size="small"
        icon={<Pause size="small" color={COLOR.error} />}
        label={"Pause"}
        onClick={pauseAll}
      />
    )}
  </Box>
);

export default Partners;
