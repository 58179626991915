import { Box, Layer } from "grommet";
import { Close } from "grommet-icons";
import React, { useState } from "react";
import { CustomTextArea } from "../../elements/custom_input";
import FormField, { FormFieldInput } from "../../elements/form_field";
import PrimaryButton from "../../elements/primary_button";
import StandardText, { COLOR } from "../../elements/standard_text";

const NewItemPopup = ({ label, onClose, onCreate, withDescription = false }) => {

    const [item, setItem] = useState({});

    return (
        <Layer>
            <Box width={'medium'} justify='center' align="center">
                <Box width={'full'} border={'bottom'}>
                    <Box direction="row" pad={'small'} justify='between' align="center">
                        <StandardText label={label} bold />
                        <Close onClick={onClose} size='small' style={{ cursor: 'pointer' }} />
                    </Box>

                </Box>
                <Box pad={'xsmall'} width='full' gap="small">
                    <FormFieldInput
                        required
                        label={'Name'}
                        size="small"
                        value={item.name}
                        onChange={event => {
                            item.name = event.target.value;
                            setItem({ ...item });
                        }}
                    />
                </Box>
                {withDescription ? <Box pad={'xsmall'} width='full' gap="small">
                    <FormField label='Description' size='small'
                        component={
                            <CustomTextArea
                                value={item.description}
                                onChange={event => setItem({ ...item, 'description': event.target.value })}
                                size='small'
                                rows={6}
                                border="normal"
                                resize={false}
                            />
                        }
                    />
                </Box> : null}
                <Box direction="row" justify="end" align="center" gap="small" pad={'medium'}>
                    <PrimaryButton
                        label={"Cancel"}
                        background={COLOR.background}
                        onClick={onClose}
                        color={COLOR.black}
                    />
                    <PrimaryButton
                        label={"Create"}
                        onClick={() => onCreate(item)}
                        background='brand'
                    />
                </Box>
            </Box>
        </Layer>
    )
}

export default NewItemPopup;

export function NewPartner ({ onClose, onCreate}) {
    const [item, setItem] = useState({});
    const [error, setError] = useState();

    const _onCreate = () => {
        if(!item.name || !item.salesforce_account_id) {
            setError('All fields are required.')
            return;
        }
        onCreate(item);
    }

    return (
        <Layer>
            <Box width={'medium'} justify='center' align="center">
                <Box width={'full'} border={'bottom'}>
                    <Box direction="row" pad={'small'} justify='between' align="center">
                        <StandardText label={"Partner"} bold />
                        <Close onClick={onClose} size='small' style={{ cursor: 'pointer' }} />
                    </Box>

                </Box>
                <Box pad={'xsmall'} width='full' gap="small">
                    <FormFieldInput
                        required
                        label={'Name'}
                        size="small"
                        value={item.name}
                        onChange={event => {
                            item.name = event.target.value;
                            setItem({ ...item });
                        }}
                    />
                </Box>
                <Box pad={'xsmall'} width='full' gap="small">
                    <FormFieldInput
                        required
                        label={'Salesforce Account ID'}
                        size="small"
                        value={item.salesforce_account_id}
                        onChange={event => {
                            item.salesforce_account_id = event.target.value;
                            setItem({ ...item });
                        }}
                    />
                </Box>
                <Box pad={'small'} width='full' gap="small">
                    {error ? <StandardText style={{ fontStyle: 'italic' }} color={COLOR.error} size='xsmall' label={error} /> : null}
                </Box>
                <Box direction="row" justify="end" align="center" gap="small" pad={'medium'}>
                    <PrimaryButton
                        label={"Cancel"}
                        background={COLOR.background}
                        onClick={onClose}
                        color={COLOR.black}
                    />
                    <PrimaryButton
                        label={"Create"}
                        onClick={_onCreate}
                        background='brand'
                    />
                </Box>
            </Box>
        </Layer>
    )
}