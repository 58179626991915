module.exports = {
  OPEN: "OPEN",
  genUid: (name) => {
    if (name) {
      return (
        name
          .normalize("NFD")
          .replace(
            /[\uE000-\uF8FF]|\uD83C[\uDC00-\uDFFF]|\uD83D[\uDC00-\uDFFF]|[\u2580-\u27BF]|\uD83E[\uDD10-\uDDFF]/g,
            ""
          )
          .replace(/[\u0300-\u036f]/g, "")
          .replace(/\//g, "")
          .replace(/  /g, " ")
          .trim()
          .replace(/ /g, "-")
          .toLowerCase() +
        "-" +
        Math.random().toString().substring(3, 7)
      ); // Make it unique inside menu
    }
  },
  REJECTED: "REJECTED",
  CANCELED: "CANCELED",
  NEW: "NEW",
  ACCEPTED: "ACCEPTED",
  PAUSED: "PAUSED"
};
