import { Box, Button, Layer, Text } from "grommet";
import {
    Close, Trash
} from "grommet-icons";
import React, { useState } from "react";
import CustomInput from "../custom_input";
import PrimaryButton from "../primary_button";
import StandardText, { COLOR } from "../standard_text";

const PopupConfirmation = ({
    primaryAction,
    primaryActionText,
    primaryActionIcon,
    secondaryAction,
    secondaryActionText,
    secondaryActionIcon,
    message,
    close,
}) => (
    <Layer>
        <Box pad='medium' justify="between" alignContent="center" align="center" style={{ height: 150 }}>
            {close ?
                <Close color="brand" onClick={close} style={{ position: 'absolute', top: 5, right: 5, cursor: 'pointer' }} size='small' /> :
                null
            }
            <Box>
                <Text>{message}</Text>
            </Box>
            <Box direction="row" gap="small">
                <PrimaryButton
                    label={primaryActionText}
                    icon={primaryActionIcon}
                    onClick={primaryAction}
                    background='brand'
                />
                <PrimaryButton
                    label={secondaryActionText}
                    icon={secondaryActionIcon}
                    background={COLOR.background}
                    onClick={secondaryAction}
                    color={COLOR.black}
                />
            </Box>
        </Box>
    </Layer>
)

const TypeToConfirmation = ({
    confirmDelete,
    description,
    pharse,
    close,
}) => {
    const [deletable, setDeletable] = useState(false);
    const [value, setValue] = useState('')
    return (
        <Layer>
            <Box pad='medium' justify="between" alignContent="center" align="center" style={{ height: 250 }} gap='medium'>
                <Close color="brand" onClick={close} style={{ position: 'absolute', top: 5, right: 5, cursor: 'pointer' }} size='small' />
                <Box>
                    <Text>Please entre the phrase <b>{pharse}</b> to delete.</Text>
                    {description ?
                        <StandardText size='xsmall' label={description} style={{fontWeight: 'bold'}} /> : null
                    }
                </Box>
                <Box>
                    <CustomInput placeholder={'Input to confirm'} size='small'
                        value={value}
                        border
                        onChange={event => {
                            setDeletable(event.target.value === pharse);
                            setValue(event.target.value);
                        }} />
                </Box>
                <Box direction="row" gap="small">
                    <Button
                        label={'Close'}
                        icon={<Close color={COLOR.black} size="small" />}
                        onClick={close}
                        size='small'
                        style={{ color: COLOR.black }}
                    />
                    <Button
                        disabled={!deletable}
                        label={'Delete'}
                        icon={<Trash color="#fff" size="small" />}
                        onClick={confirmDelete}
                        size='small'
                        style={{ background: "#E82C70", color: '#fff', border: 'none' }}
                    />
                </Box>
            </Box>
        </Layer>
    )
}

export {
    TypeToConfirmation
}

export default PopupConfirmation;