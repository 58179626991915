import { Box } from "grommet";
import React from "react";
import CustomInput from "../custom_input";
import StandardText, { COLOR } from "../standard_text";

const FormField = ({ label, component, required, bold = false, size = 'xsmall' }) => {
    return (
        <Box pad={'xsmall'} gap='xsmall' width={'full'}>
            <Box direction="row" gap="xsmall" align="center">
                <StandardText size={size} bold={bold} label={label} />
                {required ?
                    <StandardText size="small" label={"*"} bold color={COLOR.error} /> : null
                }
            </Box>
            {component}
        </Box>
    )
}

const FormFieldInput = ({ label, value, onChange, required, size = 'xsmall', icon, disabled = false, placeholder, ...rest }) => {
    return (
        <Box pad={'xsmall'} gap='xsmall' width={'full'} style={{ minHeight: 'auto' }}>
            <Box direction="row" gap="xxsmall" align="center">
                {icon ?
                    <Box direction="row" align="center" gap="xsmall">
                        <StandardText size={size} label={label} />
                        {icon}
                    </Box> :
                    <StandardText size={size} label={label} />
                }
                {required ?
                    <StandardText size="small" label={"*"} bold color={COLOR.error} /> : null
                }
            </Box>
            <CustomInput
                size={'small'}
                border
                value={value || ''}
                onChange={onChange}
                disabled={disabled}
                placeholder={placeholder}
                {...rest}
            />
        </Box>
    )
}

export default FormField;

export {
    FormFieldInput
}